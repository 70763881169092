import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../network/ApiService";

function About(props) {
  const {
    fetchAboutUs,
  } = ApiService();

  const { organizationId } = useSelector((state) => state.organization);
  const [aboutUsData, setAboutUsData] = useState([]);
  const languageStatus = sessionStorage.getItem("language");

  const getAboutUs = async () => {
    try {
      const response = await fetchAboutUs();
      setAboutUsData(response.data.aboutUsDetails);
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getAboutUs();
    }
  }, [organizationId]);

  useEffect(() => {
    if (aboutUsData.length > 0) {
      aboutUsData.length = 1;
      setAboutUsData(aboutUsData);

    }
  }, [aboutUsData]);

  return (
    // <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">
    //   <div className="container">
    //     <div className="page-heading">
    //       <h3 className="heading-text">हाम्रो बारेमा</h3>
    //     </div>
    //     {aboutUsData.length > 0
    //       ? aboutUsData.map((data, index) => {
    //           return (
    //             <div className="row no-gutters message-image" key={index}>
    //               <div className="col-md-6 d-flex align-items-stretch">
    //                 <div
    //                   className="img img-3 w-100 d-flex justify-content-center align-items-center"
    //                   style={{
    //                     backgroundImage: `url(${
    //                       data.image && data.image.trim() !== ""
    //                         ? data.image
    //                         : "assets/images/about.jpg"
    //                     })`,
    //                     position: "relative",
    //                     borderRadius: "5px 0 0 5px",
    //                   }}
    //                 >
    //                   {/* <a
    //                     href="https://vimeo.com/45830194"
    //                     className="play-video popup-vimeo d-flex align-items-center justify-content-center"
    //                   >
    //                     <span className="fa fa-play"></span>
    //                   </a> */}
    //                 </div>
    //               </div>
    //               <div className="col-md-6 wrap-about">
    //                 <div
    //                   className="bg-light px-3 px-md-4 py-5"
    //                   style={{
    //                     color: "rgb(128, 128, 128)",
    //                     borderRadius: "0 5px 5px 0",
    //                     wordBreak: "break-word",
    //                   }}
    //                 >
    //                   <div className="heading-section">
    //                     <span className="subheading">{data.header}</span>
    //                   </div>
    //
    //                   <div
    //                     className="about-message"
    //                     dangerouslySetInnerHTML={parseHtmlToView(data.message)}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //           );
    //         })
    //       : null}
    //   </div>
    // </section>
      <div
          id="page#1"
          className="uk-section-default uk-padding-large@m"
          uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: 300;"
      >
    <div
        style={{backgroundSize: '180px',
          backgroundImage: `url('images/svg-drop-primary-lighter.svg')`}}
        data-src="images/svg-drop-primary-lighter.svg"
        uk-img=""
        className="uk-background-norepeat uk-background-center-left uk-background-image@m uk-section uk-padding-remove-top"
    >
        {aboutUsData.length > 0
            ? aboutUsData.map((data, index) => {
                return (
                    <div className="uk-container" key={index}>
                        <div className="uk-container uk-container-large">
                            <div className="tm-grid-expand uk-grid-collapse uk-grid" uk-grid="true">
                                <div className="uk-width-2-5@m uk-first-column">
                                    <div
                                        className="uk-margin"
                                        uk-parallax="x: 20,20; y: 100,0; easing: 0; media: @m"
                                        style={{
                                            transform: `translateX(20px) translateY(76.8989px)`,
                                            willChange: 'transform'
                                        }}
                                    >
                                        <picture className="aspect-16-9m">
                                            <source type="image/webp" sizes="(min-width: 600px) 600px" />
                                            <img
                                                decoding="async"
                                                src={
                                                        data.image && data.image.trim() !== ""
                                                            ? data.image
                                                            : "assets/images/image.jpg"
                                                }
                                                width="600"
                                                height="700"
                                                className="el-image"
                                                alt=""
                                                loading="lazy"
                                            />
                                        </picture>
                                    </div>
                                </div>

                                <div className="uk-grid-item-match uk-flex-middle uk-width-3-5@m uk-margin">
                                    <div className="uk-panel uk-width-1-1 about-us-panel">
                                        <div
                                            className="uk-card uk-card-primary uk-card-large uk-card-body uk-margin-remove-first-child uk-margin"
                                            id="page#0"
                                        >
                                            <h3
                                                className="el-title uk-h2 uk-margin-top uk-margin-medium underline-secondary"
                                                // uk-scrollspy-class
                                            >
                                              {languageStatus === 'en' ?  `About Us` : `हाम्रो बारेमा`}
                                            </h3>

                                            <div className="el-content uk-panel uk-margin-top">
                                                <div
                                                    className="bg-light px-3 px-md-4 py-5"
                                                    style={{
                                                        borderRadius: "0 5px 5px 0",
                                                        wordBreak: "break-word",
                                                    }}
                                                >
                                                <div className="heading-section">
                                                    <span className="subheading">
                                                      {languageStatus === 'en' ?  (data.header ?? data.headerNp) : data.headerNp}
                                                    </span>
                                                  </div>

                                                  <div
                                                      className="about-message"
                                                      dangerouslySetInnerHTML={parseHtmlToView((languageStatus === 'en' ?  (data.message ?? data.messageNp) : (data.messageNp ?? data.message)))}
                                                  />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
            :null }
    </div>
</div>
  );
}

export default About;
