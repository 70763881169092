import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "./Navbar";
import TopBar from "./TopBar";
// import "./Navbar.css";

function Header(props) {
  // const IMAGEURL = process.env.REACT_APP_IMAGE_URL;
  const [org, setOrg] = useState("");
  // const organizationDetails = props.orgDetail;

  useEffect(() => {
    if (props.orgDetails) {
      setOrg(props.orgDetails);
    }
  }, [props.orgDetails]);

  return (
    <div>
    {/*<div className="navbar navbar-expand-lg sticky-top">*/}
      {/*<div className="col-md-2">*/}
      {/*  <Link className="navbar-brand ml-2 d-flex flex-row" to="/">*/}
      {/*    <img*/}
      {/*      className="logo-img"*/}
      {/*      // src="https://watersoft.com.np/WaterTariffSystem-web/documentUpload/news_89_442-pc-1664020844649.jpg?pfdrid_c=true"*/}
      {/*      // src={`${IMAGEURL}/assets/images/logo-png.png`}*/}
      {/*      src={*/}
      {/*        org.logoPath*/}
      {/*          ? org.logoPath*/}
      {/*          : `assets/images/logo-png.png`*/}
      {/*      }*/}
      {/*      alt="खानेपानी"*/}
      {/*    />*/}
      {/*    {org ? (*/}
      {/*      <div className="d-flex flex-column ms-2">*/}
      {/*        <div className="org-name">{org.nameNepali}</div>*/}
      {/*        <div className="slogan">{org.addressNepali}</div>*/}
      {/*      </div>*/}
      {/*    ) : null}*/}
      {/*  </Link>*/}
      {/*</div>*/}
      {/*<div className="col-md-10">*/}
        <TopBar contactList={props.contacts} orgDetail={props.orgDetails} />
        <Navbar orgDetail={props.orgDetails} />
      {/*</div>*/}
    </div>
  );
}

export default Header;
