function TimeOfDay(strMonth) {
    let days = {
        "Morning": "बिहान",
        "Afternoon": "दिउँसो",
        "Evening": "साँझ",
    };

    let arrNumNe = strMonth.split(' ').map(function (ch) {
        return days[ch];
    });
    return arrNumNe.join('');
}

export default TimeOfDay;
