import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Link, NavLink, useLocation} from "react-router-dom";
import ApiService from "../../network/ApiService";
// import "./Navbar.css";
import AboutIcon from './svgs/tree.svg';
import UsersIcon from './svgs/users.svg';
import NewsIcon from './svgs/newspaper.svg';
import NoticeIcon from './svgs/bell.svg';
import OutstandingIcon from './svgs/receipt.svg';
import ComplainServiceIcon from './svgs/question.svg';
import WaterScheduleIcon from './svgs/droplet.svg';
import MeterReadingScheduleIcon from './svgs/file-text.svg';
import NepaliFlag from './svgs/np.svg';
import EnglishFlag from './svgs/us.svg';

function Navbar(props) {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openEServiceDropdown, setOpenEServiceDropdown] = useState(false);
    const [messages, setMessages] = useState([]);
    const organizationDetails = props.orgDetail;
    const [language, setLanguage] = useState('en');
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchMessages,
    } = ApiService();
    const {organizationId} = useSelector((state) => state.organization);
    const aboutRef = useRef(null);
    const eServiceRef = useRef(null);
    // const [listening, setListening] = useState(false);
    // const [onClickOutside] = props;

    const showModal = () => {
        setOpenDropdown(!openDropdown);
        if (openEServiceDropdown) {
            setOpenEServiceDropdown(!openEServiceDropdown);
        }
    };

    const hideDropdown = () => {
        setOpenDropdown(!openDropdown);
    };

    const showEServiceModal = () => {
        setOpenEServiceDropdown(!openEServiceDropdown);
        if (openDropdown) {
            setOpenDropdown(!openDropdown);
        }
    };

    const hideEServiceDropdown = () => {
        setOpenEServiceDropdown(!openEServiceDropdown);
    };
    const fetchStaffMessages = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDisplayLabels);
        } catch (error) {
            console.log(error.message);
        }
    };

    const location = useLocation();
    const languageStatus = sessionStorage.getItem("language");
    useEffect(() => {
        if (organizationId) {
            fetchStaffMessages();
        }
    }, [organizationId]);

    const changeLanguage = (language : 'en') => {
        const languageStatus = sessionStorage.getItem("language");
        if (languageStatus !== ''){
            setLanguage(language);
            sessionStorage.setItem("language", language);
        }
    }


    useEffect(() => {
        const handleClickOutSide = (e) => {
            if (aboutRef.current && !aboutRef.current.contains(e.target)) {
                setOpenDropdown(false);
            }
            if (eServiceRef.current && !eServiceRef.current.contains(e.target)) {
                setOpenEServiceDropdown(false);
            }
        };
        document.body.addEventListener("click", handleClickOutSide, true);
        return () => {
            document.body.removeEventListener("click", handleClickOutSide, true);
        };
    }, []);

    return (
        // <nav
        //   className="navbar navbar-expand-lg text-white ftco_navbar"
        //   id="ftco-navbar">
        //   <div className="container-fluid">
        //     {/* <button
        //       className="navbar-toggler"
        //       type="button"
        //       data-bs-toggle="collapse"
        //       data-bs-target="#navbarSupportedContent"
        //       aria-controls="navbarSupportedContent"
        //       aria-expanded="false"
        //       aria-label="Toggle navigation"
        //     >
        //       <span className="navbar-toggler-icon"></span>
        //     </button> */}
        //     <button
        //       className="navbar-toggler"
        //       type="button"
        //       data-bs-toggle="collapse"
        //       data-bs-target="#ftco-nav"
        //       aria-controls="ftco-nav"
        //       aria-expanded="false"
        //       aria-label="Toggle navigation"
        //     >
        //       <span className="oi oi-menu"></span> Menu
        //     </button>
        //
        //     <div className="collapse navbar-collapse" id="ftco-nav">
        //       <ul className="navbar-nav ml-auto">
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/" ? "active" : ""
        //           }`}
        //         >
        //           <NavLink to="/" className="nav-link">
        //             गृहपृष्ठ
        //           </NavLink>
        //         </li>
        //         <li className="dropdown nav-item">
        //           <a
        //             type="button"
        //             className="nav-link"
        //             onClick={(e) => showModal()}
        //           >
        //             हाम्रो बारेमा <b className="fa fa-chevron-down"></b>
        //           </a>
        //           {openDropdown ? (
        //             <ul
        //               className={`dropdown-menu show`}
        //               onClick={() => hideDropdown()}
        //               ref={aboutRef}
        //             >
        //               <li>
        //                 <Link className="dropdown-item" to="/about">
        //                   संस्थाको जानकारी
        //                 </Link>
        //               </li>
        //               {Object.keys(messages).length > 0
        //                 ? Object.keys(messages).map((key) => {
        //                     return (
        //                       <li key={key}>
        //                         <Link
        //                           className="dropdown-item"
        //                           to={`/messages/${key}`}
        //                         >
        //                           {messages[key].designationNP}
        //                         </Link>
        //                       </li>
        //                     );
        //                   })
        //                 : null}
        //             </ul>
        //           ) : null}
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/news" ? "active" : ""
        //           }`}
        //         >
        //           <NavLink to="/news" className="nav-link">
        //             सुचना/गतिविधि
        //           </NavLink>
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/our-services" ? "active" : ""
        //           }`}
        //         >
        //           <Link to="/our-services" className="nav-link">
        //             हाम्रा सेवाहरू
        //           </Link>
        //         </li>
        //         <li className="dropdown nav-item">
        //           <a
        //             type="button"
        //             className="nav-link"
        //             onClick={(e) => showEServiceModal()}
        //             ref={eServiceRef}
        //           >
        //             ई-सर्विसेस <b className="fa fa-chevron-down"></b>
        //           </a>
        //           {openEServiceDropdown ? (
        //             <ul
        //               className={`dropdown-menu show`}
        //               onClick={() => hideEServiceDropdown()}
        //             >
        //               <li className="dropdown-item">
        //                 <Link to="/outstanding-report">बक्यौता विवरण</Link>
        //               </li>
        //               {/* <li className="dropdown-item">
        //                 <Link to="/about">फाइदा र लिंक</Link>
        //               </li> */}
        //               {/* <li className="dropdown-item">
        //                 <Link to="/payment-gateway">Online Payment Gateway</Link>
        //               </li> */}
        //               <li className="dropdown-item">
        //                 <Link to="/complain-service">गुनासो सेवा र ट्र्याक</Link>
        //               </li>
        //               <li className="dropdown-item">
        //                 <Link to="/water-schedule">पानी बितरण तालिका</Link>
        //               </li>
        //               <li className="dropdown-item">
        //                 <Link to="/meter-reading-schedule">रिडिङ्ग तालिका</Link>
        //               </li>
        //             </ul>
        //           ) : null}
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/download" ? "active" : ""
        //           }`}
        //         >
        //           <Link to="/download" className="nav-link">
        //             डाउनलोड
        //           </Link>
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/gallery" ? "active" : ""
        //           }`}
        //         >
        //           <NavLink to="/gallery" className="nav-link">
        //             फोटो ग्यालरी
        //           </NavLink>
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/team" ? "active" : ""
        //           }`}
        //         >
        //           <NavLink to="/team" className="nav-link">
        //             सदस्य
        //           </NavLink>
        //         </li>
        //         <li
        //           className={`nav-item ${
        //             location.pathname === "/contact" ? "active" : ""
        //           }`}
        //         >
        //           <NavLink to="/contact" className="nav-link">
        //             सम्पर्क
        //           </NavLink>
        //         </li>
        //       </ul>
        //     </div>
        //   </div>
        // </nav>
        <>
          <div className="tm-header uk-visible@l" uk-header="true">
            <div
                uk-sticky=""
                media="@l"
                cls-active="uk-navbar-sticky"
                sel-target=".uk-navbar-container"
                className="uk-sticky uk-sticky-fixed"
            >
                <div className="uk-navbar-container">
                    <div className="uk-container">
                        <nav
                            className="uk-navbar"
                            uk-navbar='{"align":"center","container":".tm-header > [uk-sticky]","boundary":".tm-header .uk-navbar-container","target-x":".tm-header .uk-navbar","dropbar":true,"target-y":".tm-header .uk-navbar-container","dropbar-anchor":".tm-header .uk-navbar-container"}'
                        >
                            <div className="uk-navbar-left">
                                <ul className="uk-navbar-nav">
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                      <NavLink to="/" className="nav-link" style={{textDecoration: 'none'}}>
                                          {languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}
                                      </NavLink>

                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom uk-parent">
                                      <Link to="/about">
                                          {languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`}
                                          <span uk-icon="icon: chevron-down; ratio: 0.8"></span>
                                      </Link>
                                        <div
                                            className="uk-navbar-dropdown uk-navbar-dropdown-dropbar"
                                            uk-drop="boundary: !.uk-navbar; stretch: x; flip: false"
                                            style={{
                                                overflowX: 'auto',
                                                maxWidth: '1132px',
                                                top: '90px',
                                                left: '30px',
                                                width: '1132px',
                                                backgroundColor: '#0f2495',
                                            }}
                                        >
                                            <div className="uk-grid-margin uk-container">
                                                <div
                                                    className="tm-grid-expand uk-child-width-1-1 uk-grid uk-grid-stack"
                                                    uk-grid="true"
                                                >
                                                    <div className="uk-width-1-1 uk-first-column">
                                                        <div data-id="menu-item-4503#0-0-0" className="uk-margin">
                                                            <div
                                                                className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-grid"
                                                                uk-grid="true"
                                                            >
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="uk-child-width-expand uk-grid-small uk-grid"
                                                                        uk-grid="true"
                                                                    >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={AboutIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/about"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                                {!openDropdown ? (
                                                                    <>
                                                                        {Object.keys(messages).length > 0
                                                                            ? Object.keys(messages).map((key, index) => {
                                                                                return (
                                                                                    <div
                                                                                        className="uk-first-column"
                                                                                        key={index}
                                                                                    >
                                                                                        <div
                                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                                            uk-grid="true"
                                                                                        >
                                                                                            <div
                                                                                                className="uk-width-auto@m uk-first-column"
                                                                                                key={key}
                                                                                            >
                                                                                                <img
                                                                                                    src={UsersIcon}
                                                                                                    width="24"
                                                                                                    height="24"
                                                                                                    className="el-image"
                                                                                                    alt=""
                                                                                                    uk-svg=""
                                                                                                    hidden=""
                                                                                                />
                                                                                            </div>
                                                                                            <div className="uk-margin-remove-first-child">
                                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                                    <Link
                                                                                                        className="uk-link-reset"
                                                                                                        to={`/messages/${key}`}
                                                                                                    >
                                                                                                        {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}
                                                                                                    </Link>
                                                                                                </h3>
                                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })
                                                                            : null}
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link className="dropdown-item">
                                            {languageStatus === 'en' ? 'News & Notices' : `सुचना/गतिविधि`}
                                            <span uk-icon="icon: chevron-down; ratio: 0.8"></span>
                                        </Link>
                                        <div
                                            className="uk-navbar-dropdown uk-navbar-dropdown-dropbar "
                                            uk-drop="boundary: !.uk-navbar; stretch: x; flip: false"
                                            style={{
                                                overflowX: 'auto',
                                                maxWidth: '1132px',
                                                top: '90px',
                                                left: '30px',
                                                width: '1132px',
                                                backgroundColor: '#0f2495',
                                            }}
                                        >
                                            <div className="uk-grid-margin uk-container">
                                                <div
                                                    className="tm-grid-expand uk-child-width-1-1 uk-grid uk-grid-stack"
                                                    uk-grid="true"
                                                >
                                                    <div className="uk-width-1-1 uk-first-column">
                                                        <div data-id="menu-item-4503#0-0-0" className="uk-margin">
                                                            <div
                                                                className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-grid"
                                                                uk-grid="true"
                                                            >
                                                                {/*समाचार*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={NewsIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/news"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'News' : `समाचार`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*सुचना/गतिविधि*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={NoticeIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/notices"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Notices' : `सुचना/गतिविधि`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                      <Link className="dropdown-item" to="/our-services">
                                          {languageStatus === 'en' ? 'Our Services' : `हाम्रा सेवाहरू`}
                                        </Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link className="dropdown-item">
                                            {languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`}
                                            <span uk-icon="icon: chevron-down; ratio: 0.8"></span>
                                        </Link>
                                        <div
                                            className="uk-navbar-dropdown uk-navbar-dropdown-dropbar "
                                            uk-drop="boundary: !.uk-navbar; stretch: x; flip: false"
                                            style={{
                                                overflowX: 'auto',
                                                maxWidth: '1132px',
                                                top: '90px',
                                                left: '30px',
                                                width: '1132px',
                                                backgroundColor: '#0f2495',
                                            }}
                                        >
                                            <div className="uk-grid-margin uk-container">
                                                <div
                                                    className="tm-grid-expand uk-child-width-1-1 uk-grid uk-grid-stack"
                                                    uk-grid="true"
                                                >
                                                    <div className="uk-width-1-1 uk-first-column">
                                                        <div data-id="menu-item-4503#0-0-0" className="uk-margin">
                                                            <div
                                                                className="uk-child-width-1-1 uk-child-width-1-2@s uk-child-width-1-4@m uk-grid-match uk-grid"
                                                                uk-grid="true"
                                                            >
                                                                {/*बक्यौता विवरण*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={OutstandingIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/outstanding-report"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*गुनासो सेवा र ट्र्याक*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={ComplainServiceIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/complain-service"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*पानी बितरण तालिका*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={WaterScheduleIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/water-schedule"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/*रिडिङ्ग तालिका*/}
                                                                <div className="uk-first-column">
                                                                    <div
                                                                        className="el-item uk-panel uk-margin-remove-first-child"
                                                                    >
                                                                        <div
                                                                            className="uk-child-width-expand uk-grid-small uk-grid"
                                                                            uk-grid="true"
                                                                        >
                                                                            <div className="uk-width-auto@m uk-first-column">
                                                                                <img
                                                                                    src={MeterReadingScheduleIcon}
                                                                                    width="24"
                                                                                    height="24"
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    uk-svg=""
                                                                                    hidden=""
                                                                                />
                                                                            </div>
                                                                            <div className="uk-margin-remove-first-child">
                                                                                <h3 className="el-title uk-text-large uk-margin-top uk-margin-remove-bottom">
                                                                                    <Link
                                                                                        className="uk-link-reset"
                                                                                        to="/meter-reading-schedule"
                                                                                    >
                                                                                        {languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}
                                                                                    </Link>
                                                                                </h3>
                                                                                {/*<div className="el-meta uk-text-meta">Nskhanepani Profile</div>*/}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link className="dropdown-item" to="/download">
                                            {languageStatus === 'en' ? 'Download' : `डाउनलोड`}
                                        </Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                        <Link className="dropdown-item" to="/gallery">
                                            {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}
                                        </Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                      <Link className="dropdown-item" to="/team">
                                          {languageStatus === 'en' ? 'Members' : `सदस्य`}
                                      </Link>
                                    </li>
                                    <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                      <Link className="dropdown-item" to="/contact">
                                          {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}
                                      </Link>
                                    </li>
                                    {/*<li className="menu-item menu-item-type-custom menu-item-object-custom">*/}
                                    {/*  <Link className="dropdown-item" to="/register">*/}
                                    {/*    + दर्ता*/}
                                    {/*  </Link>*/}
                                    {/*</li>*/}
                                </ul>
                                <div className="uk-navbar-item widget widget_custom_html" id="custom_html-3">
                                    <div className="textwidget custom-html-widget"></div>
                                </div>
                            </div>


                        </nav>
                        <div className="uk-width-auto uk-position-absolute" style={{ right: '0', top:'2rem'}}>
                            <div className="uk-light">
                                <Link
                                    onClick={() => changeLanguage('en')}
                                    className="uk-link uk-link-reset uk-first-column"
                                >
                                    <button className="uk-button-link uk-border-rounded uk-box-shadow-small"
                                            style={{ cursor:'pointer',
                                                border: languageStatus === 'en' ? '2px solid white' : ''
                                            }}>
                                        <img
                                            src={EnglishFlag}
                                            width="24"
                                            height="24"
                                            className="el-image uk-svg uk-preserve"
                                            alt=""
                                            uk-svg=""
                                            hidden=""
                                        />
                                    </button>
                                </Link>
                                <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset uk-margin-small-left">
                                    <button className="uk-button-link uk-border-rounded uk-box-shadow-small"
                                            style={{
                                                cursor:'pointer',
                                                border: languageStatus === 'np' ? '2px solid white' : ''
                                            }}>
                                            <img
                                                src={NepaliFlag}
                                                width="24"
                                                height="24"
                                                className="el-image uk-svg uk-preserve"
                                                alt=""
                                                uk-svg=""
                                                hidden=""
                                            />
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </>

    );
}

export default Navbar;
