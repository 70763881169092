import React, {useEffect, useState} from "react";
import SpriteLogo from './svgs/sprite.svg';
import ApiService from "../../network/ApiService";
import {useSelector} from "react-redux";
import {Link, NavLink} from "react-router-dom";

function TopBar(props) {
    document.addEventListener('keydown', e => {
        if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return;
        const focusElem = document.querySelector(':focus');
        const tabElements = [...document.querySelectorAll('#searchInput, #searchList>li.display>a')];
        const tabElementsCount = tabElements.length - 1;
        if (!tabElements.includes(focusElem)) return;
        e.preventDefault();
        const focusIndex = tabElements.indexOf(focusElem);
        let elemToFocus;
        if (e.key === 'ArrowUp') elemToFocus = tabElements[focusIndex > 0 ? focusIndex - 1 : tabElementsCount];
        if (e.key === 'ArrowDown') elemToFocus = tabElements[focusIndex < tabElementsCount ? focusIndex + 1 : 0];
        elemToFocus.focus();
    });
    const contactsList = props.contactList;
    const orgDetail = props.orgDetail;
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [messages, setMessages] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [showSearchList, setShowSearchList] = useState(false);
    const [language, setLanguage] = useState('en');
    const languageStatus = sessionStorage.getItem("language");
    const {
        fetchMessages,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);

    const fetchStaffMessages = async () => {
        try {
            const response = await fetchMessages();
            setMessages(response.data.mapOfIdAndDisplayLabels);
        } catch (error) {
            console.log(error.message);
        }
    };

    const changeLanguage = (language : 'en') => {
        const languageStatus = sessionStorage.getItem("language");
        if (languageStatus !== ''){
            setLanguage(language);
            sessionStorage.setItem("language", language);
        }
    }

    const toggleSearch = (e) => {
        setShowSearchList(!showSearchList)
    }

    const handleInputChange = (e) => {
        let searchList = [];
        if (e.target.value === '') {
            setSearchList([]);
            return;
        }
        const list = {
            'home': {
                'name': 'Home (गृहपृष्ठ)',
                'url': '/'
            },
            'about': {
                'name': 'About Us (संस्थाको जानकारी)',
                'url': 'about'
            },
            'news': {
                'name': 'News (समाचार)',
                'url': 'news'
            },
            'notices': {
                'name': 'Notices (सुचना/गतिविधि)',
                'url': 'notices'
            },
            'services': {
                'name': 'Our Services (हाम्रा सेवाहरू)',
                'url': 'our-services'
            },
            'outstanding-report': {
                'name': 'Outstanding Details (बक्यौता विवरण)',
                'url': 'outstanding-report'
            },
            'complain-service': {
                'name': 'Complain Register (गुनासो सेवा)',
                'url': 'complain-service'
            },
            'water-schedule': {
                'name': 'Water Distribution Schedule (पानी बितरण तालिका)',
                'url': 'water-schedule'
            },
            'meter-reading-schedule': {
                'name': 'Meter Reading Schedule (रिडिङ्ग तालिका)',
                'url': 'meter-reading-schedule'
            },
            'download': {
                'name': 'Download (डाउनलोड)',
                'url': 'download'
            },
            'gallery': {
                'name': 'Photo Gallery (फोटो ग्यालरी)',
                'url': 'gallery'
            },
            'team': {
                'name': 'Members (सदस्य)',
                'url': 'team'
            },
            'contact': {
                'name': 'Contact (सम्पर्क)',
                'url': 'contact'
            },
        };

        for (let listKey in list) {
            if ((list[listKey].name).toLowerCase().includes((e.target.value).toLowerCase())) {
                searchList.push(list[listKey]);
            }
        }
        setSearchList(searchList);
        setShowSearchList(true)
    };

    useEffect(() => {
        if (organizationId) {
            fetchStaffMessages();
        }
        if (Object.keys(contactsList).length > 0) {
            setPhone(contactsList.find((item) => item.contactType === "Phone"));
            setEmail(contactsList.find((item) => item.contactType === "Email"));
        }
    }, [contactsList]);
    return (
        // <div className="wrap topbar">
        //   <div className="container">
        //     <div className="row">
        //       <div className="col-md-4 d-flex align-items-center"></div>
        //       <div className="col-md-8 d-flex justify-content-md-end">
        //       <p className="mb-0 phone pl-md-2 social-media mt-1">
        //       {/* {orgDetail ?
        //        orgDetail.facebookUrl : ""} */}
        //             {orgDetail && orgDetail.contactNumberNp && orgDetail.contactNumberNp.trim() !== "" ? (
        //               <a className="mr-2">
        //                 <span className="fa fa-phone mr-1"></span>
        //                 {orgDetail.contactNumberNp}
        //               </a>
        //             ) : null}
        //             {email ? (
        //               <a>
        //                 <span className="fa fa-paper-plane mr-1"></span>
        //                 {email.contactAddress}
        //               </a>
        //             ) : null}
        //           </p>
        //         <div className="social-media">
        //           <p className="mb-0 d-md-flex">
        //             <a
        //               href={orgDetail ? orgDetail.facebookUrl : ""}
        //               className="d-md-flex align-items-center justify-content-center sm-mr-5"
        //             >
        //               <span className="fa fa-brands fa-facebook">
        //                 <i className="sr-only">Facebook</i>
        //               </span>
        //             </a>
        //             <a
        //               href={orgDetail ? orgDetail.twitterUrl : ""}
        //               className="d-md-flex align-items-center justify-content-center sm-mr-5"
        //             >
        //               <span className="fa fa-brands fa-twitter">
        //                 <i className="sr-only">Twitter</i>
        //               </span>
        //             </a>
        //             <a
        //               href={orgDetail ? orgDetail.linkedInUrl : ""}
        //               className="d-md-flex align-items-center justify-content-center sm-mr-5"
        //             >
        //               <span className="fa fa-brands fa-linkedin">
        //                 <i className="sr-only">Linkedin</i>
        //               </span>
        //             </a>
        //             <a
        //               href={orgDetail ? orgDetail.youtubeUrl : ""}
        //               className="d-md-flex align-items-center justify-content-center sm-mr-5"
        //             >
        //               <span className="fa fa-brands fa-youtube">
        //                 <i className="sr-only">Youtube</i>
        //               </span>
        //             </a>
        //           </p>
        //         </div>
        //       </div>
        //     </div>
        //   </div>
        // </div>
        <>
    {/*    <div className="topbar">*/}
            {/*    <div className="uk-container">*/}
            {/*        <nav className="uk-navbar">*/}
            {/*            <div className="uk-navbar-left" style={{gap: '16px'}}>*/}
            {/*                <a href="/" aria-label="Back to home" className="uk-logo uk-navbar-item">*/}
            {/*                    <picture>*/}
            {/*                        <source type="image/webp" sizes="(min-width: 55px) 55px" />*/}
            {/*                        <img*/}
            {/*                            alt="nskhanepani"*/}
            {/*                            loading="eager"*/}
            {/*                            // src={`assets/images/logo-ns.jpg`}*/}
            {/*                            src={NSLogo}*/}
            {/*                            style={{height: '72px'}}*/}
            {/*                        />*/}
            {/*                    </picture>*/}
            {/*                </a>*/}
            {/*                <a href="/" aria-label="Back to home" className="uk-logo uk-navbar-item">*/}
            {/*                    <picture>*/}
            {/*                        <source type="image/webp" sizes="(min-width: 55px) 55px" />*/}
            {/*                        <img*/}
            {/*                            className="rotate-y-axis"*/}
            {/*                            alt="nskhanepani"*/}
            {/*                            loading="eager"*/}
            {/*                            src={*/}
            {/*                                orgDetail.logoPath*/}
            {/*                                    ? orgDetail.logoPath*/}
            {/*                                    : `assets/images/logo-png.png`*/}
            {/*                            }*/}
            {/*                            style={{height: '72px'}}*/}
            {/*                        />*/}
            {/*                    </picture>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*            <div className="uk-navbar-right">*/}
            {/*                /!*Searchbar*!/*/}
            {/*                <div>*/}
            {/*                    <form*/}
            {/*                        id="ns-search"*/}
            {/*                        action=""*/}
            {/*                        method="get"*/}
            {/*                        role="search"*/}
            {/*                        className="uk-search uk-search-default uk-width-1-1"*/}
            {/*                        target="_self"*/}
            {/*                    >*/}
            {/*                        <button uk-search-icon="" className="uk-search-icon-flip uk-icon uk-search-icon">*/}
            {/*                        </button>*/}
            {/*                        <input*/}
            {/*                            name="s"*/}
            {/*                            type="search"*/}
            {/*                            placeholder="Search"*/}
            {/*                            className="uk-search-input uk-light"*/}
            {/*                            required=""*/}
            {/*                        />*/}
            {/*                    </form>*/}
            {/*                </div>*/}
            {/*                /!*Other Icons*!/*/}
            {/*                <div className="uk-grid uk-grid-divider">*/}
            {/*                    <div className="uk-width-auto">*/}
            {/*                        <div className="uk-grid uk-child-width-expand">*/}
            {/*                            <div>*/}
            {/*                                <a href={orgDetail ? orgDetail.facebookUrl : ""}>*/}
            {/*                                    <svg className="svg svg-light">*/}
            {/*                                        <use xlinkHref={SpriteLogo+`#icon-facebook`} />*/}
            {/*                                    </svg>*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div>*/}
            {/*                                <a href={orgDetail ? orgDetail.twitterUrl : ""}>*/}
            {/*                                    <svg className="svg svg-light">*/}
            {/*                                        <use xlinkHref={SpriteLogo+`#icon-twitter`} />*/}
            {/*                                    </svg>*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div>*/}
            {/*                                <a href={orgDetail ? orgDetail.linkedInUrl : ""}>*/}
            {/*                                    <svg className="svg svg-light">*/}
            {/*                                        <use xlinkHref={SpriteLogo+`#icon-linkedin`} />*/}
            {/*                                    </svg>*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                            <div>*/}
            {/*                                <a href={orgDetail ? orgDetail.youtubeUrl : ""}>*/}
            {/*                                    <svg className="svg svg-light">*/}
            {/*                                        <use xlinkHref={SpriteLogo+`#icon-youtube`} />*/}
            {/*                                    </svg>*/}
            {/*                                </a>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    /!*<div className="uk-width-auto">*!/*/}
            {/*                    /!*    <div className="uk-grid uk-light">*!/*/}
            {/*                    /!*        <a href="#" className="uk-link uk-link-reset">En</a>*!/*/}
            {/*                    /!*        <a href="#" className="uk-link uk-link-reset">Nep</a>*!/*/}
            {/*                    /!*    </div>*!/*/}
            {/*                    /!*</div>*!/*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </nav>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="topbar uk-visible@l">
        <div className="uk-container">
            <nav className="uk-navbar">
                <div className="uk-navbar-left" style={{gap: '16px'}}>
                    <Link to="/" aria-label="Back to home" className="uk-logo uk-navbar-item">
                        {
                            orgDetail.bannerImage ?
                                <>
                                    <picture>
                                        <source type="image/webp" sizes="(min-width: 55px) 55px" />
                                        <img
                                            alt=""
                                            loading="eager"
                                            src={
                                                orgDetail.bannerImage
                                                    ? orgDetail.bannerImage
                                                    : `assets/images/image.jpg`
                                            }
                                            style={{height: '72px'}}
                                        />
                                    </picture>
                                </>
                                : null
                        }
                    </Link>
                    <Link to="/" aria-label="Back to home" className="uk-logo uk-navbar-item uk-visible@m">
                        {
                            orgDetail.logoPath ?
                                <>
                                    <picture>
                                        <source type="image/webp" sizes="(min-width: 55px) 55px" />
                                        <img
                                            className="rotate-y-axis"
                                            alt=""
                                            loading="eager"
                                            src={
                                                orgDetail.logoPath
                                                    ? orgDetail.logoPath
                                                    : `assets/images/image.jpg`
                                            }
                                            style={{height: "72px"}}
                                        />
                                    </picture>
                                </>
                                : null
                        }
                    </Link>
                </div>
                <div className="uk-navbar-right">
                    <div className="uk-visible@m uk-position-relative">
                        <form
                            onSubmit={(e) => e.preventDefault()}
                            id="ns-search"
                            action=""
                            method="get"
                            role="search"
                            className="uk-search uk-search-default uk-width-1-1"
                            target="_self"
                        >
                            <button uk-search-icon="" className="uk-search-icon-flip uk-icon uk-search-icon">
                            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><circle
                                fill="none"
                                stroke="#000"
                                strokeWidth="1.1"
                                cx="9"
                                cy="9"
                                r="7"
                            ></circle><path
                                fill="none"
                                stroke="#000"
                                strokeWidth="1.1"
                                d="M14,14 L18,18 L14,14 Z"
                            ></path></svg></button>
                            <input
                                id="searchInput"
                                name="s"
                                type="search"
                                placeholder={ languageStatus === 'en' ? 'Search' : `खोज` }
                                className="uk-search-input uk-light"
                                autoComplete="off"
                                onClick={(e) => handleInputChange(e)}
                                onChange={(e) => handleInputChange(e)}
                            />
                        </form>
                        <div className="uk-position-absolute search_bar">
                            {
                                showSearchList ?
                                    searchList.length > 0 ?
                                        <ul className="uk-list" id="searchList">
                                        {searchList.map((data, index) => {
                                            return (
                                                <>
                                                    <li
                                                        className="el-item uk-link-reset display"
                                                        onClick={(e) => toggleSearch()}
                                                    >
                                                        <Link to={data.url}>
                                                            {data.name}
                                                        </Link>
                                                    </li>
                                                </>
                                            )
                                        })}
                                    </ul>
                                        : null
                                    : null
                            }

                        </div>
                    </div>
                    <div className="uk-grid uk-grid-divider" uk-grid="">
                        <div className="uk-width-auto uk-first-column">
                            <div className="uk-grid uk-child-width-expand" uk-grid="">
                                <div className="uk-first-column">
                                    <a target="_blank" href={orgDetail ? orgDetail.facebookUrl : ""}>
                                        <svg className="svg svg-light">
                                            <use xlinkHref={SpriteLogo + `#icon-facebook`} />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href={orgDetail ? orgDetail.twitterUrl : ""}>
                                        <svg className="svg svg-light">
                                            <use xlinkHref={SpriteLogo + `#icon-twitter`} />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href={orgDetail ? orgDetail.linkedInUrl : ""}>
                                        <svg className="svg svg-light">
                                            <use xlinkHref={SpriteLogo + `#icon-linkedin`} />
                                        </svg>
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href={orgDetail ? orgDetail.youtubeUrl : ""}>
                                        <svg className="svg svg-light">
                                            <use xlinkHref={SpriteLogo + `#icon-youtube`} />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
        <div className="tm-header-mobile uk-hidden@l" uk-header="">
             <div
                 uk-sticky=""
                 show-on-up=""
                 animation="uk-animation-slide-top"
                 cls-active="uk-navbar-sticky"
                 sel-target=".uk-navbar-container"
                 className="uk-sticky"
             >
                <div className="uk-navbar-container">
                    <div className="uk-container uk-container-expand">
                        <nav
                            className="uk-navbar"
                            uk-navbar="{&quot;container&quot;:&quot;.tm-header-mobile > [uk-sticky]&quot;}"
                        >
                            <div className="uk-navbar-left uk-flex-1">
                                <Link to="/" aria-label="Back to home" className="uk-logo uk-navbar-item">
                                    <picture>
                                        <img
                                            alt="nskhanepani"
                                            loading="eager"
                                            src={`assets/images/logo-ns.jpg`}
                                            style={{maxHeight: `80px`}}
                                        />
                                    </picture>
                                </Link>
                            </div>
                            <div className="uk-navbar-right">
                                <a
                                    uk-toggle=""
                                    aria-label="Open Menu"
                                    href="#tm-dialog-mobile"
                                    className="uk-navbar-toggle"
                                    aria-expanded="false"
                                >
                                    <div uk-navbar-toggle-icon="" className="uk-icon uk-navbar-toggle-icon">
                                    </div>
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div
                id="tm-dialog-mobile"
                uk-offcanvas="container: true; overlay: true"
                mode="push"
                flip=""
                className="uk-offcanvas"
            >
                <div className="uk-offcanvas-bar uk-flex uk-flex-column">
                    <button
                        className="uk-offcanvas-close uk-close-large uk-icon uk-close"
                        type="button"
                        uk-close=""
                        uk-toggle="cls: uk-close-large; mode: media; media: @s"
                    ></button>
                    <div className="uk-margin-auto-bottom">
                        <div className="uk-grid uk-child-width-1-1 uk-grid-stack" uk-grid="true">
                            <div className="uk-position-relative">
                                <div className="uk-panel widget widget_nav_menu " id="nav_menu-2">
                                    {/*<form*/}
                                    {/*    onSubmit={(e) => e.preventDefault()}*/}
                                    {/*    id="ns-search"*/}
                                    {/*    action=""*/}
                                    {/*    method="get"*/}
                                    {/*    role="search"*/}
                                    {/*    className="uk-search uk-search-default uk-width-1-1 uk-margin-small-bottom"*/}
                                    {/*    target="_self"*/}
                                    {/*>*/}
                                    {/*    <button*/}
                                    {/*        uk-search-icon=""*/}
                                    {/*        className="uk-search-icon-flip uk-icon uk-search-icon"*/}
                                    {/*    >*/}
                                    {/*    </button>*/}
                                    {/*    <input*/}
                                    {/*        id="searchInput"*/}
                                    {/*        name="s"*/}
                                    {/*        type="search"*/}
                                    {/*        placeholder="Search"*/}
                                    {/*        className="uk-search-input uk-light"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        onClick={(e) => handleInputChange(e)}*/}
                                    {/*        onChange={(e) => handleInputChange(e)}*/}
                                    {/*    />*/}
                                    {/*</form>*/}
                                    <div className="uk-position-absolute search_bar">
                                        {
                                            showSearchList ?
                                                searchList.length > 0 ?
                                                    <ul className="uk-list" id="searchList">
                                                    {searchList.map((data, index) => {
                                                        return (
                                                            <>
                                                                <li
                                                                    className="el-item uk-link-reset display"
                                                                    onClick={(e) => toggleSearch()}
                                                                >
                                                                    <Link to={data.url}>
                                                                        {data.name}
                                                                    </Link>
                                                                </li>
                                                            </>
                                                        )
                                                    })}
                                                </ul>
                                                    : null
                                                : null
                                        }

                                    </div>
                                    <ul className="uk-nav uk-nav-primary uk-nav-divider uk-nav-custom">
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <NavLink to="/" className="nav-link" style={{textDecoration: 'none'}}>
                                                {languageStatus === 'en' ? 'Home' : `गृहपृष्ठ`}
                                            </NavLink>
                                        </li>
                                        <li className="">
                                            <ul className="uk-nav" uk-nav="true">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children uk-parent">

                                                    <a
                                                        href="/about"
                                                        className=""
                                                        uk-scroll=""
                                                    >  {languageStatus === 'en' ? 'About Us' : `हाम्रो बारेमा`}<span
                                                        uk-icon="plus"
                                                        className="show-on-closed"
                                                    ></span><span
                                                        className="show-on-open"
                                                        uk-icon="minus"
                                                    ></span></a>
                                                    <ul className="uk-nav-sub" hidden uk-nav="true">
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/about">
                                                                {languageStatus === 'en' ? 'Office Information' : `संस्थाको जानकारी`}
                                                            </Link>
                                                        </li>
                                                        {Object.keys(messages).length > 0
                                                            ? Object.keys(messages).map((key, index) => {
                                                                return (
                                                                    <li
                                                                        className="menu-item menu-item-type-custom menu-item-object-custom"
                                                                        key={index}
                                                                    >
                                                                        <Link
                                                                            className="uk-link-reset"
                                                                            to={`/messages/${key}`}
                                                                        >
                                                                            {languageStatus === 'en' ? messages[key].designation : messages[key].designationNP} {languageStatus === 'en' ? `'s Speech` : `को मन्तव्य`}
                                                                        </Link></li>
                                                                )
                                                            }) : null}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="">
                                            <ul className="uk-nav" uk-nav="true">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children uk-parent">
                                                    <a
                                                        href="#"
                                                        className=""
                                                        uk-scroll=""
                                                    >  {languageStatus === 'en' ? 'News & Notices' : `सुचना/गतिविधि`}<span
                                                        uk-icon="plus"
                                                        className="show-on-closed"
                                                    ></span><span
                                                        className="show-on-open"
                                                        uk-icon="minus"
                                                    ></span></a>
                                                    <ul className="uk-nav-sub" hidden uk-nav="true">
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/news">
                                                                {languageStatus === 'en' ? 'News' : `समाचार`}
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/notices">
                                                                {languageStatus === 'en' ? 'Notices' : `सुचना/गतिविधि`}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <Link className="dropdown-item" to="/our-services">
                                                {languageStatus === 'en' ? 'Our Services' : `हाम्रा सेवाहरू`}
                                            </Link>
                                        </li>
                                        <li className="">
                                            <ul className="uk-nav" uk-nav="true">
                                                <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children uk-parent">

                                                    <a
                                                        href="#eservices"
                                                        className=""
                                                        uk-scroll=""
                                                    > {languageStatus === 'en' ? 'E-Services' : `ई-सर्विसेस`}<span
                                                        uk-icon="plus"
                                                        className="show-on-closed"
                                                    ></span><span
                                                        className="show-on-open"
                                                        uk-icon="minus"
                                                    ></span></a>
                                                    <ul className="uk-nav-sub" hidden uk-nav="true">
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/outstanding-report">
                                                                {languageStatus === 'en' ? 'Outstanding Details' : `बक्यौता विवरण`}
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/complain-service">
                                                                {languageStatus === 'en' ? 'Complain Register' : `गुनासो सेवा`}
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link className="uk-link-reset" to="/water-schedule">
                                                                {languageStatus === 'en' ? 'Water Distribution Schedule' : `पानी बितरण तालिका`}
                                                            </Link>
                                                        </li>
                                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                                            <Link
                                                                className="uk-link-reset"
                                                                to="/meter-reading-schedule"
                                                            >
                                                                {languageStatus === 'en' ? 'Meter Reading Schedule' : `रिडिङ्ग तालिका`}
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <Link className="dropdown-item" to="/download">
                                                {languageStatus === 'en' ? 'Download' : `डाउनलोड`}
                                            </Link>
                                        </li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <Link className="dropdown-item" to="/gallery">
                                                {languageStatus === 'en' ? 'Photo Gallery' : `फोटो ग्यालरी`}
                                            </Link>
                                        </li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <Link className="dropdown-item" to="/team">
                                                {languageStatus === 'en' ? 'Members' : `सदस्य`}
                                            </Link>
                                        </li>
                                        <li className="menu-item menu-item-type-custom menu-item-object-custom">
                                            <Link className="dropdown-item" to="/contact">
                                                {languageStatus === 'en' ? 'Contact' : `सम्पर्क`}
                                            </Link>
                                        </li>
                                        {/*<li className="menu-item menu-item-type-custom menu-item-object-custom"><a*/}
                                        {/*    href="#contact"*/}
                                        {/*    uk-scroll=""*/}
                                        {/*> + दर्ता</a></li>*/}
                                    </ul>
                                    <hr />
                                    <div>
                                        <div className="">
                                            <div className="uk-grid uk-child-width-auto" uk-grid="true">
                                                <div>
                                                    <a href="">
                                                        <svg className="svg svg-light">
                                                            <use xlinkHref={`assets/images/sprite.svg#icon-facebook`}></use>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="">
                                                        <svg className="svg svg-light">
                                                            <use xlinkHref={`assets/images/sprite.svg#icon-twitter`}></use>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="">
                                                        <svg className="svg svg-light">
                                                            <use xlinkHref={`assets/images/sprite.svg#icon-linkedin`}></use>
                                                        </svg>
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href="">
                                                        <svg className="svg svg-light">
                                                            <use xlinkHref={`assets/images/sprite.svg#icon-youtube`}></use>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="uk-margin-small-top">*/}
                                        {/*    <div className="uk-grid uk-light" uk-grid="true">*/}
                                        {/*        <Link onClick={() => changeLanguage('en')} className="uk-link uk-link-reset">En</Link>*/}
                                        {/*        <Link onClick={() => changeLanguage('np')} className="uk-link uk-link-reset">Nep</Link>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default TopBar;
