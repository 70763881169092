import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import ApiService from "../../../../network/ApiService";

function PaymentGateway(props) {
    const {
        fetchNewsUpdates,
        fetchContact,
        fetchAboutUs,
        fetchSliderImages,
        fetchStaffs,
        fetchGalleries,
        fetchPaymentGatewayList,
    } = ApiService();

    const {organizationId} = useSelector((state) => state.organization);
    const [paymentGatewayList, setPaymentGatewayList] = useState([]);
    const getPaymentGatewayList = async () => {
        try {
            const response = await fetchPaymentGatewayList();
            setPaymentGatewayList(response.data.paymentGateWayDetail);
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        if (organizationId) {
            getPaymentGatewayList();
        }
    }, [organizationId]);

    return (
        // <section className="ftco-section ftco-no-pb ftco-no-pt mt-3">
        //   {paymentGatewayList.length > 0 ? (
        //     <div className="container">
        //       <div className="page-heading">
        //         <h3 className="heading-text">भुक्तानी साझेदार</h3>
        //       </div>
        //       <div className="d-flex justify-content-center no-gutters gap-3">
        //         {paymentGatewayList && paymentGatewayList.length > 0 ? (
        //           <>
        //             {paymentGatewayList.map((data, index) => {
        //               return (
        //                 <div className="col-md-2" key={index}>
        //                   <div className="img d-flex justify-content-center align-items-center">
        //                     <a
        //                       href={
        //                         data.payementGateWayURL !== "N/A" &&
        //                         data.payementGateWayURL.trim() !== ""
        //                           ? data.payementGateWayURL
        //                           : null
        //                       }
        //                       target={
        //                         data.payementGateWayURL !== "N/A" &&
        //                         data.payementGateWayURL.trim() !== ""
        //                           ? "_blank"
        //                           : null
        //                       }>
        //                       {data.paymentGatewayLogo.trim() !== "N/A" ? (
        //                         <img
        //                           src={data.paymentGatewayLogo}
        //                           className="img-thumbnail payment-gateway"
        //                         />
        //                       ) : (
        //                         <img
        //                           src={"assets/images/placeholder.jpg"}
        //                           className="img-thumbnail payment-gateway"
        //                         />
        //                       )}
        //                     </a>
        //                   </div>
        //                   <div className="heading-section text-center">
        //                     {data.payementGateWayURL != "N/A" &&
        //                     data.payementGateWayURL.trim() != "" ? (
        //                       <a
        //                         href={data.payementGateWayURL}
        //                         target="_blank"
        //                         className="subheading"
        //                       >
        //                         {data.paymentGateWayShortNameNp}
        //                       </a>
        //                     ) : (
        //                       <a className="subheading">
        //                         {data.paymentGateWayShortNameNp}
        //                       </a>
        //                     )}
        //                   </div>
        //                 </div>
        //               );
        //             })}
        //           </>
        //         ) : null}
        //       </div>
        //     </div>
        //   ) : null}
        // </section>
        <div
            className="uk-section-default uk-section uk-section-large"
            // uk-scrollspy="target: [uk-scrollspy-class]; cls: uk-animation-slide-bottom-medium; delay: false;"
        >
          {paymentGatewayList.length > 0 ? (
              <div className="uk-container">
        <div className="tm-grid-expand uk-child-width-1-1 uk-grid-margin uk-grid uk-grid-stack" uk-grid="true">
            <div className="uk-width-1-1 uk-first-column">
                <h2
                    className="uk-h2 uk-margin-large uk-width-2xlarge uk-margin-auto uk-text-center"
                    // uk-scrollspy-class
                    data-id="page#4-0-0-0"
                >
                    <span className="section-underline">
                        <span className="underline-secondary">
                            { props.language === 'en' ? `Payment Partners` : `भुक्तानी साझेदार` }
                        </span>
                    </span>
                </h2>
                <div
                    data-id="page#4-0-0-1"
                    className="payment-grid uk-width-xlarge uk-margin-auto uk-text-center"
                    /* uk-scrollspy-class*/
                >
                    <div
                        className="uk-child-width-1-1 uk-child-width-1-3@s uk-child-width-1-3@m uk-flex-center uk-grid-collapse uk-grid-match uk-grid"
                        uk-grid=""
                    >

                {paymentGatewayList && paymentGatewayList.length > 0 ? (
                    <>
                {paymentGatewayList.map((data, index) => {
                    return (
                        <div className="uk-first-column payment-gateway" key={index}>
                            <div className="el-item uk-panel uk-margin-remove-first-child">
                                <picture>
                                    <source type="image/webp" sizes="(min-width: 163px) 163px" />
                                    <a
                                        href={
                                            data.payementGateWayURL !== "N/A" &&
                                            data.payementGateWayURL.trim() !== ""
                                                ? data.payementGateWayURL
                                                : null
                                        }
                                        target={
                                            data.payementGateWayURL !== "N/A" &&
                                            data.payementGateWayURL.trim() !== ""
                                                ? "_blank"
                                                : null
                                        }
                                    >
                                        {data.paymentGatewayLogo.trim() !== "N/A" ? (
                                            <img
                                                decoding="async"
                                                src={data.paymentGatewayLogo}
                                                width="163"
                                                height="56"
                                                className="el-image"
                                                alt=""
                                                loading="lazy"
                                            />
                                        ) : (
                                            <img
                                                decoding="async"
                                                src={"assets/images/image.jpg"}
                                                width="163"
                                                height="56"
                                                className="el-image"
                                                alt=""
                                                loading="lazy"
                                            />
                                        )}
                                    </a>
                                </picture>
                            </div>

                        </div>
                    );
                })}
              </>
                ) : null}
                    </div>
                </div>
            </div>
        </div>
    </div>
          ) : null}
</div>
    );
}

export default PaymentGateway;
