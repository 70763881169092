function EngToNepDay(strMonth) {
    let days = {
        "Sunday": "आइतवार",
        "Monday": "सोमवार",
        "Tuesday": "मगलवार",
        "Wednesday": "बुधवार",
        "Thursday": "बिहिवार",
        "Friday": "शुक्रवार",
        "Saturday": "शनिवार"
    };

    let arrNumNe = strMonth.split(' ').map(function (ch) {
        return days[ch];
    });
    return arrNumNe.join('');
}

export default EngToNepDay;
