import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiService from "../../network/ApiService";
import "./Popup.css";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCube} from "swiper";

function Popup(props) {
  const {
    fetchNewsUpdates,
    fetchContact,
    fetchAboutUs,
    fetchSliderImages,
    fetchStaffs,
    fetchGalleries,
    fetchDownloads,
    fetchPopup,
  } = ApiService();

  var modal = document.querySelector(".modal");
  var ukFlexTop = document.querySelector(".uk-flex-top");
  var trigger = document.querySelector(".trigger");
  var closeButton = document.querySelector(".close-button");

  const toggleModal = () => {
    document.querySelector('.modal').classList.remove('show-modal');
    setTimeout(() => {
      document.querySelector('.modal').remove();
    }, 500)
  }

  const { organizationId } = useSelector((state) => state.organization);
  const [popUp, setPopUp] = useState([]);
  const getPopUp = async () => {
    try {
      const response = await fetchPopup();
      setPopUp(response.data.messageWithImg);
      toggleModal();
    } catch (error) {
      console.log(error.message);
    }
  };

  const parseHtmlToView = (str) => {
    return { __html: str };
  };

  useEffect(() => {
    if (organizationId) {
      getPopUp();
    }
  }, [organizationId]);

  return (
    <>
      {/*{props.showModal && popUp.length > 0 ? (*/}
      {/*  <div className="custom-modal-backdrop">*/}
      {/*    <div*/}
      {/*      className="col-md-6 col-sm-12 custom-modal rounded d-flex flex-column w-auto p-0"*/}
      {/*      tabIndex="-1"*/}
      {/*    >*/}
      {/*      <a*/}
      {/*        className="align-self-end position-fixed m-2"*/}
      {/*        style={{*/}
      {/*          zIndex: 1026,*/}
      {/*          cursor: "pointer",*/}
      {/*        }}*/}
      {/*        onClick={() => {*/}
      {/*          props.onClose();*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        /!* <i className="fa fa-sharp fa-solid fa-download"></i> *!/*/}
      {/*        <i className="fa fa-sharp fa-solid fa-xmark fa-2x"></i>*/}
      {/*      </a>*/}
      {/*      <div className="modal-body h-100 p-0">*/}
      {/*        {popUp.length > 0*/}
      {/*          ? popUp.map((data, index) => {*/}
      {/*              return (*/}
      {/*                <div key={index}>*/}
      {/*                  <div className="card-header text-center">*/}
      {/*                    {data.header}*/}
      {/*                  </div>*/}
      {/*                  <div className="card-body pt-0 modal-card-body">*/}
      {/*                    <div*/}
      {/*                      className="card-text mt-2"*/}
      {/*                      dangerouslySetInnerHTML={parseHtmlToView(*/}
      {/*                        data.message*/}
      {/*                      )}*/}
      {/*                    ></div>*/}
      {/*                    {data.imageUrl ? (*/}
      {/*                      <img*/}
      {/*                        src={data.imageUrl}*/}
      {/*                        className="notice-img mt-4"*/}
      {/*                        alt="notice"*/}
      {/*                      />*/}
      {/*                    ) : null}*/}
      {/*                  </div>*/}
      {/*                </div>*/}
      {/*              );*/}
      {/*            })*/}
      {/*          : null}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {props.showModal && popUp.length > 0 ? (
      <>
        <div className="modal show-modal">
            <div className="modal-content">
                {/*<span className="close-button" onClick={() => toggleModal()}>×</span>*/}
              <button className="uk-modal-close-outside" type="button" uk-close="true" style={{
                top: `3rem`,
                right: `5rem`
              }} onClick={() => toggleModal()}/>
              <div className="uk-open" id="popup">
                <div className="uk-margin-auto-vertical ">
                  <div className="uk-padding">
                    <div className="">
                        <div className="">
                            <Swiper
                                style={{transition: 'transform 0.3s ease'}}
                                modules={[EffectCube, Autoplay]}
                                effect="cube"
                                autoplay={true}
                                loop={true}
                                grabCursor={true}
                                cubeEffect={{
                                  shadow: false,
                                }}
                            >
                                {popUp.length > 0
                                    ? popUp.map((data, index) => {
                                      return (
                                          <>
                                              {
                                                (Array.isArray(data.imageUrlList))
                                                  ?
                                                    (data.imageUrlList).length > 0 ?
                                                        (
                                                            (data.imageUrlList).map((image, imageIndex) => {
                                                              return (
                                                                  <>
                                                                    <div key={`image_list_` + imageIndex}>
                                                                      <SwiperSlide
                                                                          className="popupImage"
                                                                          key={`swiper_` + index + imageIndex}>
                                                                            <img
                                                                                key={`image_` + imageIndex + data.id}
                                                                                src={image !== 'n/a' ? (image) : `assets/images/image.jpg`}
                                                                            />
                                                                        <div
                                                                            className="el-content"
                                                                            key={ `message_` + index + data.id}
                                                                            style={{color: 'black', marginLeft:'10px', marginTop:'6rem', position: 'absolute'}}
                                                                            dangerouslySetInnerHTML={parseHtmlToView(
                                                                                data.message
                                                                                    .split("<p><br></p>")
                                                                                    .join("")
                                                                            )}
                                                                        >
                                                                        </div>
                                                                      </SwiperSlide>
                                                                    </div>
                                                                  </>
                                                              )
                                                            })
                                                        ) : `assets/images/image.jpg`
                                                    :
                                                    <>
                                                      <SwiperSlide
                                                          className="popupImage"
                                                          key={`single_image_` + index}>
                                                          <img
                                                              src={data.imageUrl !== 'n/a' ? (data.imageUrl) : `assets/images/image.jpg`}
                                                          />
                                                      </SwiperSlide>
                                                    </>
                                              }
                                            </>
                                      );
                                    })
                                    : null}
                            </Swiper>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </>
      ) : null}
    </>
  );
}

export default Popup;
